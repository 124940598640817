import React from "react";
import "./PreviousCommandOutput.css";

function PreviousCommandOutput(props) {
  
  const Linkedin = "https://www.linkedin.com/in/chris-nelis-587723238/"
  const GitHub = "https://github.com/CNelis"
  const CV = "./CV/Chris_Nelis_Software_Developer.docx"
  const {label, command} = props;

  return (
    <div className={"dark-form-input"}>
      <div className="cursor">
        <div className={"input"}>
          <pre style={{margin:"0px"}}>
            {label.map((item, index) => {
              return command === "links" ? 
              <>
              <div>{"----------------- Links ------------------"}</div>
              <div>{" "}</div>
              <a href={Linkedin}>{"Linkedin"}</a> 
              <div>{" "}</div>                                                   
              <a href={GitHub}>{"GitHub"}</a>
              {/* <div>{" "}</div>                                                   
              <a href={CV} download>{"CV"}</a> */}
              </>
              :
              <div key={index}>{item} </div>
            }
            )}

          </pre>
        </div>
      </div>
    </div>
  );
}

export default PreviousCommandOutput;
