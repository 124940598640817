import React from "react";
import "./FormInput.css";

function FormInput(props) {
  const { label, rootdir, onChange, handleSubmit, ...inputProps } = props;

  return (
    <div className={"dark-form-input"}>
      <div className="cursor">
        <div> {rootdir}
          <input
            {...inputProps}
            autoComplete={"off"}
            onChange={onChange}
            autoFocus
            className={"input"}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit(inputProps.value)
              }
          }}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default FormInput;
