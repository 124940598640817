import React from "react";
import "./PreviousInput.css";

function PreviousInput(props) {
  const { label, rootdir} = props;

  return (
    <div className={"dark-form-input"}>
      <div className="cursor">
        <div>{rootdir}
          <label
            className={"input"}
          >
            {label}
          </label>
        </div>
      </div>
    </div>
  );
}

export default PreviousInput;
