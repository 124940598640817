import './App.css';
import FormInput from "./Components/FormInput/FormInput.js"
import PreviousInput from "./Components/PreviousInput/PreviousInput.js"
import PreviousCommandOutput from "./Components/PreviousCommandOutput/PreviousCommandOutput.js"
import { useState } from "react";
import {Commands} from "./Commands/Commands.js"

function App() {

  const rootdir = ".\\nel>"

  const inputs = [
    {
      id: 1,
      name: "user_input",
      type: "text",
      placeholder: "",
      label: "user_input",
      required: true,
    },
  ];

  const [values, setValues] = useState({
    user_input: "",
  });


  const [oldCommands, setOldCommands] = useState([])


  const handleSubmit = (inputProps) => {

    const matchedCommand = Commands.find((command) => {
      return command.name == inputProps
    });

    var currentCommandOutput = matchedCommand === undefined ? {name:"undef", output : ["Sorry I dont recognise this command, type \"help\" for the command list"]} : matchedCommand

    if (currentCommandOutput.name === "clear") {
      setOldCommands([])
    } 

    else{
      let commandHistory = {"command": inputProps, "output": currentCommandOutput.output}
      setOldCommands((oldCommands) => [...oldCommands, commandHistory])
    }

    setValues({ user_input: "" });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="App">
      <div className="App-header">
      {oldCommands.length > 0 && oldCommands.map((obj, index) => {
        return (
          <div key={index}>
            <PreviousInput              
              label={obj.command}
              rootdir={rootdir}
            >
            </PreviousInput>
            <PreviousCommandOutput
              label={obj.output}
              command={obj.command}
            >
            </PreviousCommandOutput>
          </div>
        )
      })
      }
      <FormInput
        key={inputs[0].id}
        {...inputs[0]}
        value={values[inputs[0].name]}
        onChange={onChange}
        handleSubmit={handleSubmit}
        rootdir={rootdir}
        >
        </FormInput>
      </div>
    </div>
  );
}

export default App;
