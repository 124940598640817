
const Linkedin = "https://www.linkedin.com/in/chris-nelis-587723238/"
const GitHub = "https://github.com/CNelis"


export const Commands = [
  {
    name: "help",
    output: [
        "---------------- Commands ----------------",
        " ",
        "about           learn more about this page",
        "cv                             print my cv",
        "projects      learn more about my projects",
        "links                        contact links",
        "help                    print command list",
        "clear                       clear terminal"
    ]
  },
  {
    name: "about",
    output: [
        "----------------- About ------------------",
        " ",
        "Experienced Software Developer looking for ",
        "challenging roles and exposure to new      ",
        "technology. Skilled in creating attractive ",
        "and fast web applications using React and  ",
        "JavaScript. Adept in using Python for data ",
        "analysis and Django for application        ",
        "development. Comfortable working with a    ",
        "variety of DevOps tools and cloud platforms"
    ],
  },
  {
    name: "cv",
    output: [
        "------------------- CV -------------------",
        " ",
        "Experience -------------------------------",
        " ",
        "Senior Software Engineer                  ",
        "EPAM                                      ",
        "July 2022 - Present                       ",
        " ",
        "Software Developer                        ",
        "CGI                                       ",
        "Sept 2019 - June 2022                     ",
        " ",
        "Junior Project Manager                    ",
        "CGI                                       ",
        "Sept 2017 - Sept 2019                     ",
        " ",
        "Sales Team Leader                         ",
        "Home Fundraising                          ",
        "Jan 2017 - Sept 2017                      ",
        " ",
        "iGEM Competitor                           ",
        "University of Dundee                      ",
        "May 2016 - Nov 2017                       ",
        " ",
        "Education --------------------------------",
        " ",
        "BSc(Hons) Software Development            ",
        "Edinburgh Napier University               ",
        "2017 - 2021                               ",
        " ",
        "BSc Anatomy                               ",
        "University of Dundee                      ",
        "2013 - 2016                               ",

    ],
  },
  {
    name: "projects",
    output: [
        "---------------- Projects -----------------",
        " ",
        "Interested in algorithmic trading, finanical",
        "markets and volatitlity products. Most of my",                             
        "projects are on github or are confidential. ",                                 
    ]
  },
  {
    name: "clear",
    output: "",
  },

];
